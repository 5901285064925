import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: inherit;

  h1 {
    font-size: 6em;
    color: #ffffff;
    text-shadow: rgba(255, 255, 255, 0.24) 0px 0px 0.6em;
  }

  p {
    color: #88c3f7;
    text-shadow: rgba(16, 16, 16, 0.459) 0px 0px 0.6em;
    font-size: 2.1em;
    margin-top: 25px;
  }

  @media (min-width: 310px) and (max-width: 549px) {
    h1 {
      font-size: 2.35em;
    }

    p {
      font-size: 1.4em;
    }
  }

  @media (min-width: 550px) and (max-width: 749px) {
    h1 {
      font-size: 3em;
    }

    p {
      font-size: 1.6em;
    }
  }

  @media (min-width: 750px) and (max-width: 1100px) {
    h1 {
      font-size: 4em;
    }

    p {
      font-size: 1.8em;
    }
  }
`;
