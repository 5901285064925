import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background: rgb(1, 23, 118);
    background: radial-gradient(circle, rgb(35, 61, 139)  10%, rgb(1, 23, 118) 90%);
  }
`;

export default GlobalStyles;
