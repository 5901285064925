import { Background } from "./Home.styles";

export default function Home() {
  return (
    <Background>
      <h1>Personal Wallet</h1>
      <p>Coming Soon</p>
    </Background>
  );
}
